<div class="h-100 d-flex flex-column">
	<app-navbar></app-navbar>
	<div class="h-100 d-flex flex-row" style="min-height: 0px">
		<app-dashboard
			*ngIf="userRole !== 'Student'"
			[ngClass]="{ 'd-none': isAuthenticated === false || noDashboard }"
			class="dashboard"
			id="dashboard"></app-dashboard>
		<!-- [@slideFromBottom]="outlet.isActivated && outlet.activatedRoute?.routeConfig?.path" TODO: throws ExpressionChangedAfterItHasBeenCheck when animation is active. It should be fixed -->
		<div id="main-page">
			<div id="page-content" [ngClass]="{ 'page-content-margins': addPageMargins }">
				<div *ngIf="!noPageContentErrorContainer" id="error-container"></div>
				<router-outlet #outlet="outlet"></router-outlet>
			</div>
		</div>
	</div>
</div>
