<div *ngIf="!loading">
	<div *ngFor="let pair of urlPairs; let i = index">
		<div class="mb-3 d-flex">
			<input
				pInputText
				type="text"
				[(ngModel)]="pair.oldUrl"
				placeholder="Old URL"
				style="width: 45%" />
			<input
				style="width: 45%"
				pInputText
				type="text"
				class="mx-4"
				[(ngModel)]="pair.newUrl"
				placeholder="New URL" />
			<p-button
				(click)="deleteUrlPair(pair.id, i)"
				styleClass="p-button p-button-secondary p-button-outlined"
				label="Remove"></p-button>
		</div>
	</div>
	<p-button
		(click)="addPair()"
		styleClass="p-button p-button-secondary"
		class="me-4"
		label="Add URLs"></p-button>
	<p-button
		(click)="saveChanges()"
		styleClass="p-button p-button-secondary"
		[loading]="isSaving"
		label="Save Changes"></p-button>
</div>
<div class="mt-3 text-danger" *ngIf="areAnyUrlsEmpty">{{ emptyUrlMessage }}</div>
<div class="d-flex justify-content-center" style="margin-top: 4rem" *ngIf="loading">
	<p-progressSpinner></p-progressSpinner>
</div>
