<ul
	[ngClass]="{
		'student-nav-items': userRole === 'Student'
	}"
	class="navbar-nav dashboard">
	<ng-container
		*ngFor="let route of allowedRoutes; trackBy: trackByFn"
		[ngTemplateOutlet]="
			route.name !== 'Saas::Menu:Saas' &&
			route.name !== 'AbpUiNavigation::Menu:Administration' &&
			route.name !== 'Support' &&
			route.name !== 'Certifications' &&
			route.name !== 'CEs'
				? defaultLink
				: route.name !== 'Saas::Menu:Saas'
				? newdropdownLink
				: noLink
		"
		[ngTemplateOutletContext]="{ $implicit: route }"></ng-container>

	<ng-template #defaultLink let-route>
		<li
			[ngClass]="{
				'mb-4': userRole !== 'Student'
			}"
			class="nav-item"
			style="position: relative"
			*abpPermission="route.requiredPolicy">
			<a
				class="nav-link dashboard-item d-flex flex-row align-items-center"
				[routerLink]="[route.path]"
				routerLinkActive="active"
				[routerLinkActiveOptions]="
					route.name !== '::Menu:Home' ? { exact: false } : { exact: true }
				">
				<i
					class="isax me-3 fs-4"
					[(ngClass)]="dashboardIcons[getIconIndex(route.name)]"></i>
				<span style="font-size: 16px">
					{{ route.name | abpLocalization }}
					<!-- {{ route.name | abpLocalization }} -->
					<!-- if there is an error here in the future this is what was changed -->
				</span>
			</a>
		</li>
	</ng-template>

	<!-- Administration -->
	<ng-template #newdropdownLink let-route>
		<ng-container *ngIf="route.children?.length">
			<li
				[ngClass]="{
					'mb-4': userRole !== 'Student'
				}"
				class="nav-item"
				style="position: relative"
				*abpPermission="route.requiredPolicy">
				<a
					class="nav-link dashboard-dropdown-item d-flex flex-row align-items-center"
					style="font-size: 16px"
					role="button"
					(click)="updateCollapsedValues(route.name)">
					<i
						*ngIf="
							route.name == 'AbpUiNavigation::Menu:Administration' ||
							route.name == 'Support'
						"
						class="isax me-3 isax-user-octagon fs-4"></i>
					{{ route.name | abpLocalization }}
					<i
						*ngIf="userRole == 'Student'"
						class="dropdown-icon align-self-center ms-2 fas fa-chevron-down"
						[class.closed]="collapsedValues[extractName(route.name)]"></i>
				</a>
				<div
					#collapse="ngbCollapse"
					[(ngbCollapse)]="!collapsedValues[extractName(route.name)]"
					class="text-wrap"
					[id]="extractName(route.name)"
					style="padding-left: 0.4rem; max-width: fit-content">
					<ng-container
						*ngTemplateOutlet="
							forTemplate;
							context: { $implicit: route }
						"></ng-container>
				</div>
			</li>
		</ng-container>
	</ng-template>

	<ng-template #newdropdownChild let-child>
		<div *abpPermission="child.requiredPolicy">
			<div>
				<a
					class="dashboard-dropdown-item"
					style="text-decoration: none; font-size: 16px"
					role="button"
					(click)="
						collapsedValues[extractName(child.name)] =
							!collapsedValues[extractName(child.name)]
					">
					{{ child.name | abpLocalization }}
				</a>
			</div>
			<div
				#collapse="ngbCollapse"
				[(ngbCollapse)]="!collapsedValues[extractName(child.name)]"
				class="submenu-header"
				[id]="extractName(child.name)">
				<ng-container
					*ngTemplateOutlet="forTemplate; context: { $implicit: child }"></ng-container>
			</div>
		</div>
	</ng-template>

	<ng-template #forTemplate let-route>
		<ng-container *ngFor="let child of route.children">
			<ng-template
				[ngTemplateOutlet]="child.children?.length ? newdropdownChild : defaultChild"
				[ngTemplateOutletContext]="{ $implicit: child }"></ng-template>
		</ng-container>
	</ng-template>

	<ng-template #defaultChild let-child>
		<ng-container *ngIf="child.path">
			<div class="dropdown-submenu" *abpPermission="child.requiredPolicy">
				<a
					class="dropdown-item dashboard-dropdown-item fw-light d-flex align-items-center"
					style="font-size: 16px"
					(click)="closedCollapsedValues()"
					[routerLink]="[child.path]"
					routerLinkActive="active"
					[routerLinkActiveOptions]="{ exact: true }">
					<span *ngIf="userRole == 'Student'" [class]="child.iconClass"></span>
					{{ child.name | abpLocalization }}
				</a>
			</div>
		</ng-container>
	</ng-template>
</ul>
