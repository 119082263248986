import { eLayoutType, RoutesService } from '@abp/ng.core';
import { APP_INITIALIZER } from '@angular/core';

export const STUDENT_ACCOUNT_ROUTE_PROVIDER = [
	{ provide: APP_INITIALIZER, useFactory: configureRoutes, deps: [RoutesService], multi: true },
];

function configureRoutes(routes: RoutesService) {
	return () => {
		routes.add([
			{
				path: '/student-account',
				name: 'Student Account',
				layout: eLayoutType.application,
				requiredPolicy: 'AAH.Students',
			},
		]);
	};
}
