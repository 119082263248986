<h6 class="gray-text">
	For large video files it may take the service 5-10 minutes to finish processing the video before
	it is able to be viewed.
</h6>
<div *ngFor="let item of linkedNames" class="mt-3 mb-4 d-flex flex-column">
	<div class="w-100 d-flex align-items-center justify-content-between mb-3">
		<h5>
			{{ '::' + item[0] | abpLocalization }}
		</h5>
		<p-button
			[loading]="isLoading[item[0]] || isLoading[item[1]]"
			styleClass="p-button-secondary"
			(click)="saveMedia(item)"
			label="Save Change"></p-button>
	</div>
	<div class="w-100 d-xl-flex justify-content-between">
		<div class="w-100 mb-3 mb-xl-0 me-0 me-xl-3">
			<span class="gray-text">Video:</span>
			<div
				class="w-100 d-flex justify-content-between align-items-center"
				*ngIf="existingFiles[item[0]] && !existingFiles[item[0]].deleted">
				<a routerLink="/media-files/media-file/{{ existingFiles[item[0]].id }}">
					{{ existingFiles[item[0]].fileName }}
				</a>
				<button
					pButton
					pRipple
					type="button"
					icon="pi pi-trash"
					class="p-button-rounded p-button-text p-button-danger"
					title="Delete"
					(click)="deleteItem(item[0])"></button>
			</div>
			<div [hidden]="existingFiles[item[0]] && !existingFiles[item[0]].deleted">
				<p-fileUpload
					name="{{ item[0] }}"
					#videoInput
					accept=".mp4,.webm,.ogg,.mov"
					customUpload="true"
					[showUploadButton]="false"
					[showCancelButton]="false"
					chooseLabel="Browse"
					maxFileSize="10000000000"
					fileLimit="1"
					files="">
					<ng-template let-files pTemplate="content">
						<p *ngIf="files.length === 0" class="text-muted">
							Drag your video here, or browse for one.
						</p>
					</ng-template>
				</p-fileUpload>
			</div>
		</div>
		<div class="w-100">
			<span class="gray-text">Thumbnail:</span>
			<div
				class="w-100 d-flex justify-content-between align-items-center"
				*ngIf="existingFiles[item[1]] && !existingFiles[item[1]].deleted">
				<a routerLink="/media-files/media-file/{{ existingFiles[item[1]].id }}">
					{{ existingFiles[item[1]].fileName }}
				</a>
				<button
					pButton
					pRipple
					type="button"
					icon="pi pi-trash"
					class="p-button-rounded p-button-text p-button-danger"
					title="Delete"
					(click)="deleteItem(item[1])"></button>
			</div>
			<div [hidden]="existingFiles[item[1]] && !existingFiles[item[1]].deleted">
				<p-fileUpload
					name="{{ item[1] }}"
					#thumbnailInput
					accept=".jpeg,.png,.jpg,.tif,.tiff"
					customUpload="true"
					[showUploadButton]="false"
					[showCancelButton]="false"
					chooseLabel="Browse"
					maxFileSize="10000000000"
					fileLimit="1"
					files="">
					<ng-template let-files pTemplate="content">
						<p *ngIf="files.length === 0" class="text-muted">
							Drag your thumbnail here, or browse for one.
						</p>
					</ng-template>
				</p-fileUpload>
			</div>
		</div>
	</div>
</div>
