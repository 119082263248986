import { eLayoutType, RoutesService } from '@abp/ng.core';
import { APP_INITIALIZER } from '@angular/core';

export const PARTNER_PROFILE_ROUTE_PROVIDER = [
	{ provide: APP_INITIALIZER, useFactory: configureRoutes, deps: [RoutesService], multi: true },
];

function configureRoutes(routes: RoutesService) {
	return () => {
		routes.add([
			{
				path: '/my-profile',
				iconClass: 'fas fa-file-alt',
				name: 'My Profile',
				layout: eLayoutType.application,
				requiredPolicy: 'AAH.Partners',
				order: 20,
				invisible: true,
			},
		]);
	};
}
