import { CoreModule } from '@abp/ng.core';
import { GdprConfigModule } from '@volo/abp.ng.gdpr/config';
import { SettingManagementConfigModule } from '@abp/ng.setting-management/config';
import { ThemeSharedModule, ToasterService } from '@abp/ng.theme.shared';
import { ErrorHandler, InjectionToken, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommercialUiConfigModule } from '@volo/abp.commercial.ng.ui/config';
import { AccountAdminConfigModule } from '@volo/abp.ng.account/admin/config';
import { AccountPublicConfigModule } from '@volo/abp.ng.account/public/config';
import { AuditLoggingConfigModule } from '@volo/abp.ng.audit-logging/config';
import { IdentityConfigModule } from '@volo/abp.ng.identity/config';
import { LanguageManagementConfigModule } from '@volo/abp.ng.language-management/config';
import { registerLocale } from '@volo/abp.ng.language-management/locale';
import { SaasConfigModule } from '@volo/abp.ng.saas/config';
import { TextTemplateManagementConfigModule } from '@volo/abp.ng.text-template-management/config';
//import { HttpErrorComponent, ThemeLeptonXModule } from '@volosoft/abp.ng.theme.lepton-x';
//import { SideMenuLayoutModule } from '@volosoft/abp.ng.theme.lepton-x/layouts';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { APP_ROUTE_PROVIDER } from './route.provider';
import { OpeniddictproConfigModule } from '@volo/abp.ng.openiddictpro/config';
import { PARTNERS_PARTNER_ROUTE_PROVIDER } from './partners/partner/providers/partner-route.provider';
import { APPROVALS_APPROVAL_ROUTE_PROVIDER } from './approvals/approval/providers/approval-route.provider';
import { REPORTS_ROUTE_PROVIDER } from './reports/providers/reports-route.provider';
import { ThemeBasicModule } from '@abp/ng.theme.basic';
import { PrimengToasterService } from './services/primeng-toaster.service.service';
import { SharedModule } from './shared/shared.module';
import { LEADS_LEAD_ROUTE_PROVIDER } from './leads/lead/providers/lead-route.provider';
import { AccordionModule } from 'primeng/accordion';
import { PARTNER_STUDENTS_ROUTE_PROVIDER } from './partner-students/provider/partner-students-route.provider';
import { PARTNER_PROFILE_ROUTE_PROVIDER } from './partner-profile/provider/partner-profile-route.provider';
import { SUPPORT_ROUTE_PROVIDER } from './support/provider/support-route.provider';
import { SETTINGS_ROUTE_PROVIDER } from './settings/provider/settings-route.provider';
import { STUDENT_ACCOUNT_ROUTE_PROVIDER } from './student-account/provider/student-account-route.provider';
import { STUDENT_COURSE_CONTENT_ROUTE_PROVIDER } from './student-course-content/providers/student-course-content-route.provider';
import * as Sentry from "@sentry/angular-ivy";

import { ErrorsManagementService } from './shared/http-errors/errors-management.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AbpOAuthModule } from '@abp/ng.oauth';
import { DASHBOARD_ROUTE_PROVIDER } from './dashboard/providers/dashboard-route.provider';
import { MEMBER_ROUTE_PROVIDER } from './members/providers/members-route.provider';
import { CONTENT_ROUTE_PROVIDER } from './content/providers/content-route.providers';
import { REGISTRATIONS_ROUTE_PROVIDER } from './registrations/providers/registrations-route.provider';
import { CODES_ROUTE_PROVIDER } from './codes/providers/codes-route.provider';
import { STUDENT_STUDY_GUIDES_ROUTE_PROVIDER } from './student-study-guides/providers/student-study-guides-route.provider';
import { STUDENT_CERTIFICATIONS_ROUTE_PROVIDER } from './student-certifications/providers/student-certifications-route.provider';
import { CES_ROUTE_PROVIDER } from './ces/provider/ces-route.provider';
import { CustomErrorHandler } from 'src/errorHandler';
// import { CHARTS_ROUTE_PROVIDER } from './charts/providers/charts-route.provider';

@NgModule({
	declarations: [AppComponent],
	imports: [
		BrowserModule,
		AccordionModule,
		BrowserAnimationsModule,
		AppRoutingModule,
		SharedModule,
		CoreModule.forRoot({
			environment,
			registerLocaleFn: registerLocale(),
		}),
		// ThemeSharedModule.forRoot({
		//   httpErrorConfig: {
		//     errorScreen: {
		//       component: HttpErrorComponent,
		//       forWhichErrors: [401, 403, 404, 500],
		//       hideCloseIcon: true,
		//     },
		//   },
		// }),
		AccountAdminConfigModule.forRoot(),
		AbpOAuthModule.forRoot(),
		AccountPublicConfigModule.forRoot(),
		IdentityConfigModule.forRoot(),
		LanguageManagementConfigModule.forRoot(),
		SaasConfigModule.forRoot(),
		AuditLoggingConfigModule.forRoot(),
		OpeniddictproConfigModule.forRoot(),
		TextTemplateManagementConfigModule.forRoot(),
		SettingManagementConfigModule.forRoot(),
		ThemeSharedModule.forRoot({
			validation: {
				blueprints: {
					maxlength: '::MaxLength[{{ requiredLength }}]',
				},
			},
		}),
		ThemeBasicModule.forRoot(),
		//SideMenuLayoutModule.forRoot(),
		CommercialUiConfigModule.forRoot(),
		GdprConfigModule.forRoot({
			privacyPolicyUrl: 'gdpr-cookie-consent/privacy',
			cookiePolicyUrl: 'gdpr-cookie-consent/cookie',
		}),
	],
	providers: [
		APP_ROUTE_PROVIDER,
		PARTNERS_PARTNER_ROUTE_PROVIDER,
		APPROVALS_APPROVAL_ROUTE_PROVIDER,
		REPORTS_ROUTE_PROVIDER,
		PARTNER_STUDENTS_ROUTE_PROVIDER,
		PARTNER_PROFILE_ROUTE_PROVIDER,
		SUPPORT_ROUTE_PROVIDER,
		SETTINGS_ROUTE_PROVIDER,
		DASHBOARD_ROUTE_PROVIDER,
		MEMBER_ROUTE_PROVIDER,
		CONTENT_ROUTE_PROVIDER,
		REGISTRATIONS_ROUTE_PROVIDER,
		CODES_ROUTE_PROVIDER,
		STUDENT_STUDY_GUIDES_ROUTE_PROVIDER,
		STUDENT_CERTIFICATIONS_ROUTE_PROVIDER,
		CES_ROUTE_PROVIDER,
		STUDENT_ACCOUNT_ROUTE_PROVIDER,
		STUDENT_COURSE_CONTENT_ROUTE_PROVIDER,
		// CHARTS_ROUTE_PROVIDER,
		//	CMS_ROUTE_PROVIDER,
		{ provide: AppComponent, useClass: AppComponent },
		{ provide: ToasterService, useClass: PrimengToasterService },
		{ provide: HTTP_INTERCEPTORS, useClass: ErrorsManagementService, multi: true },
		{ provide: ErrorHandler, useValue: new CustomErrorHandler({
			showDialog: false,
			logErrors: true
		})
	},
		LEADS_LEAD_ROUTE_PROVIDER,
	],
	bootstrap: [AppComponent],
})
export class AppModule {}
