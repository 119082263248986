import { eLayoutType, RoutesService } from '@abp/ng.core';
import { APP_INITIALIZER } from '@angular/core';

export const APPROVALS_APPROVAL_ROUTE_PROVIDER = [
	{ provide: APP_INITIALIZER, useFactory: configureRoutes, deps: [RoutesService], multi: true },
];

function configureRoutes(routes: RoutesService) {
	return () => {
		routes.add([
			{
				path: '/approvals',
				iconClass: 'fas fa-file-alt',
				name: '::Menu:Approvals',
				layout: eLayoutType.application,
				order: 2,
			},
		]);
	};
}
